@if (cardData) {
  @for (cardRow of cardData; track cardRow) {
    <app-card-row-item
      [rowName]="cardRow.rowName"
      [rowIcon]="cardRow.rowIcon"
      [rowData]="cardRow.rowData"
      [showAccountStatus]="cardRow.showAccountStatus"
      [showTooltipList]="cardRow.showTooltipList"
    ></app-card-row-item>
  }
}
