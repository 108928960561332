import {Pipe, PipeTransform} from '@angular/core';
/*
 * encode url string to deal with Special characters such as @,?,/
 * Usage:
 *   value | encodeURI
 * Example:
 *   ['/property-summary/' + propertyDetails.id | encodeURI]
 *   formats : string
 */
@Pipe({
  name: 'encodeURI',
})
export class EncodeURIPipe implements PipeTransform {
  transform(param?: string): string | undefined {
    if (!param) {
      return param;
    }
    return encodeURIComponent(param);
  }
}
