/* eslint-disable @typescript-eslint/no-explicit-any */
import {ApplicationRef, ComponentRef, EnvironmentInjector, Injectable, Type, createComponent} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateComponentService {
  contentRef?: ComponentRef<any>;

  constructor(
    private applicationRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  removeComponentFromBody(componentRef: ComponentRef<any> | undefined): void {
    if (!componentRef) {
      return;
    }
    this.applicationRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  appendComponentToBody<T, K>(component: Type<T>, contentComponent?: Type<K>): ComponentRef<T> {
    if (contentComponent) {
      this.contentRef = createComponent(contentComponent, {environmentInjector: this.injector});
      const ref = createComponent(component, {
        environmentInjector: this.injector,
        projectableNodes: [[this.contentRef.location.nativeElement]],
      });
      document.body.appendChild(ref.location.nativeElement);
      this.applicationRef.attachView(ref.hostView);
      this.applicationRef.attachView(this.contentRef.hostView);
      return ref;
    } else {
      const ref = createComponent(component, {environmentInjector: this.injector});
      document.body.appendChild(ref.location.nativeElement);
      this.applicationRef.attachView(ref.hostView);
      return ref;
    }
  }
}
