/* eslint-disable @angular-eslint/directive-selector */
import {Directive, HostListener} from '@angular/core';
import {FormControlName} from '@angular/forms';
import {spaceRegex} from '@Shared/constants/regular-expressions';

@Directive({
  selector: '[formControlName]',
})
export class TrimWhitespaceDirective {
  constructor(private formControlName?: FormControlName) {}

  @HostListener('paste', ['$event.target.value'])
  @HostListener('blur', ['$event.target.value'])
  @HostListener('keydown.enter', ['$event.target.value'])
  trimValue(): void {
    const control = this.formControlName?.control;
    if (control && typeof control.value === 'string') {
      const currentValue = control.value;

      if (
        this.hasWhiteSpace(currentValue.charAt(0)) ||
        this.hasWhiteSpace(currentValue.charAt(currentValue.length - 1))
      ) {
        control.patchValue(currentValue.trim());
      }
    }
  }

  private hasWhiteSpace(s: string): boolean {
    return spaceRegex.test(s);
  }
}
