import {Directive, HostBinding, Output, EventEmitter, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Input() disabled = false;
  @Output() fileDropped = new EventEmitter();

  constructor() {
    this.fileOver = false;
  }

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    if (this.disabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: Event) {
    if (this.disabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    if (this.disabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const file = event.dataTransfer?.files;
    if (file && file.length > 0) {
      this.fileDropped.emit(file);
    }
  }
}
