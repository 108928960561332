export enum DesignSystemIcon {
  location = 'place',
  assignment = 'assignment_turned_in',
  person = 'person',
  home = 'home',
  eye = 'visibility',
  edit = 'edit',
  removeUser = 'person_off',
  delete = 'delete',
  deleteForever = 'delete_forever',
  error = 'error_outline',
  filter = 'filter_alt',
  close = 'close',
  remove = 'do_not_disturb_on',
  add = 'add_circle',
  window = 'window',
  list = 'list_alt',
  listWithoutBorder = 'list',
  group = 'group',
  deactivate = 'disabled_by_default',
  suburbSearch = 'location_searching',
  map = 'map',
  pending = 'pending',
  file = 'file_copy',
  domain = 'domain',
  payments = 'payments',
}
