<div
  class="tab__container"
  [ngClass]="{'new-cma': newCmaSearch}"
>
  @for (tab of tabs; track tab; let i = $index) {
    <button
      class="tab"
      [ngClass]="{'tab--selected': viewType === tab.name}"
      (click)="onViewTypeChange(tab.name)"
    >
      <mat-icon class="tab__icon">{{ tab.icon }}</mat-icon>
      <p class="tab__text quantum-button">{{ tab.name }}</p>
    </button>
  }
</div>
