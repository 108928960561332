<div class="warning">
  <mat-icon
    class="icon icon__warning"
    fontSet="material-icons-outlined"
    >error</mat-icon
  >
  <p class="warning__text">{{ text }}</p>
  <button
    mat-icon-button
    (click)="closeButtonClicked.emit()"
  >
    <mat-icon class="icon icon__close">close</mat-icon>
  </button>
</div>
