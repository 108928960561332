import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewTab} from '@Shared/component-library/interfaces/view-tab';
import {ViewType} from '@Shared/enums/view-type';

@Component({
  selector: 'app-view-tab',
  templateUrl: './view-tab.component.html',
  styleUrls: ['./view-tab.component.scss'],
})
export class ViewTabComponent {
  @Input() tabs: ViewTab[] = [];
  @Input() viewType: ViewType = ViewType.Card;
  @Input() newCmaSearch = false;
  @Output() viewTypeChange = new EventEmitter<ViewType>();

  onViewTypeChange(viewType: ViewType) {
    this.viewType = viewType;
    this.viewTypeChange.emit(viewType);
  }
}
