import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() label = '';
  @Input() type = 'text';
  @Input() formGroupParent = new UntypedFormGroup({});
  @Input() controlName = '';
}
