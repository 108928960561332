<mat-form-field
  [formGroup]="formGroupParent"
  appearance="fill"
  class="form-field"
  formErrorContainer
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    [formControlName]="controlName"
    errorClass="form-field__error--fill"
  />
</mat-form-field>
