/* eslint-disable @typescript-eslint/member-ordering */
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {handleApiMessage} from '@Shared/helpers/error-message-helpers/api-error-message-helper';
import {EMPTY, Observable, ReplaySubject, Subject, throwError} from 'rxjs';
import {NotificationService} from '@Core/services/notifications/notification.service';
import {NotificationType} from '@Shared/enums/notification-type';

const HTTP_REQUEST_ERROR = 0;

const KNOWN_ERRORS = [
  HttpStatusCode.BadRequest,
  HttpStatusCode.Unauthorized,
  HttpStatusCode.Forbidden,
  HttpStatusCode.RequestTimeout,
  HttpStatusCode.InternalServerError,
  HttpStatusCode.ServiceUnavailable,
  HTTP_REQUEST_ERROR,
];

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private resetFormErrorSubject = new Subject<string>();
  private resetAllFormsErrorSubject = new ReplaySubject<void>(1);
  resetFormError$ = this.resetFormErrorSubject.asObservable();
  resetAllFormsError$ = this.resetAllFormsErrorSubject.asObservable();

  constructor(private notificationService: NotificationService) {}

  resetFormError(controlName: string) {
    this.resetFormErrorSubject.next(controlName);
  }

  resetAllFormsError() {
    this.resetAllFormsErrorSubject.next();
  }

  public handleError(errorMessage?: string): (errorResponse: HttpErrorResponse) => Observable<never> {
    return (errorResponse: HttpErrorResponse) => {
      if (isKnownError(errorResponse.status)) {
        this.notificationService.openNotificationDialog(
          NotificationType.error,
          errorMessage ?? handleApiMessage(errorResponse) ?? errorResponse.message
        );
        return EMPTY;
      }
      return throwError(() => errorResponse);
    };
  }
}

export const isKnownError = (errorCode: number): boolean => KNOWN_ERRORS.includes(errorCode);
