/* eslint-disable @angular-eslint/directive-selector */
import {forbiddenCharactersValidator} from '@Shared/validators/forbidden-characters-validator';
import {Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[formControlName]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ForbiddenCharactersDirective),
      multi: true,
    },
  ],
})
export class ForbiddenCharactersDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return forbiddenCharactersValidator()(control);
  }
}
