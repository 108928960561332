import {ComponentRef, Directive, Inject, Input, OnDestroy, OnInit, Optional, ViewContainerRef} from '@angular/core';
import {AbstractControl, NgControl, ValidationErrors} from '@angular/forms';
import {ErrorHandlerService} from '@Core/services/error-handlers/error-handler.service';
import {FormErrorComponent} from '@Shared/components/atoms/form-error/form-error.component';
import {FORM_ERRORS} from '@Shared/constants/form-errors';
import {formPatternErrors} from '@Shared/constants/form-pattern-errors';
import {FormErrors} from '@Shared/interfaces/form-errors';
import {Subject, takeUntil} from 'rxjs';
import {FormErrorContainerDirective} from './form-error-container.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControlName]',
})
export class FormErrorsDirective implements OnInit, OnDestroy {
  @Input() customErrors: Record<string, string> = {};
  @Input() errorClass?: string;
  @Input() compareTo?: string;
  @Input() formControlName?: string;

  componentRef?: ComponentRef<FormErrorComponent>;
  container: ViewContainerRef;

  private readonly destroy$ = new Subject();

  constructor(
    private formControlInstance: NgControl,
    private errorHandlerService: ErrorHandlerService,
    @Inject(FORM_ERRORS) private errors: FormErrors,
    @Optional() formErrorContainer: FormErrorContainerDirective,
    viewContainerRef: ViewContainerRef
  ) {
    this.container = formErrorContainer ? formErrorContainer.viewContainerRef : viewContainerRef;
  }

  get control() {
    return this.formControlInstance.control as AbstractControl;
  }

  ngOnInit(): void {
    this.control?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const formErrors = this.formControlInstance.errors;

      this.handleCompareTo(this.compareTo);

      if (formErrors) {
        this.handleFormErrors(formErrors);
      } else if (this.componentRef) {
        this.setError(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private handleFormErrors(formErrors: ValidationErrors) {
    const firstErrorKey = Object.keys(formErrors)[0];
    if (firstErrorKey === 'pattern') {
      this.handlePatternError(formErrors);
    } else {
      this.handleError(formErrors, firstErrorKey);
    }
  }

  private handlePatternError(formErrors: ValidationErrors) {
    const patternErrorMessage = formPatternErrors[formErrors.pattern.requiredPattern];

    if (patternErrorMessage) {
      this.setError(patternErrorMessage);
    }
  }

  private handleError(formErrors: ValidationErrors, firstErrorKey: string) {
    const getError = this.errors[firstErrorKey as keyof FormErrors];

    if (getError) {
      const text = this.customErrors[firstErrorKey] || getError(formErrors[firstErrorKey]);
      this.setError(text);
    }
  }

  private handleCompareTo(controlName: string | undefined) {
    if (!controlName) {
      return;
    }

    const control = this.control.parent?.get(controlName);
    control?.updateValueAndValidity({emitEvent: false, onlySelf: true});

    if (!control?.errors) {
      this.errorHandlerService.resetFormError(controlName);
    }
  }

  private setError(text: string | null) {
    if (!this.componentRef) {
      this.componentRef = this.container.createComponent<FormErrorComponent>(FormErrorComponent);
    }

    this.componentRef.instance.text = text;
    this.componentRef.instance.controlName = this.formControlName;

    if (this.errorClass) {
      this.componentRef.instance.customClass = this.errorClass;
    }
  }
}
