import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {SwipeActions} from '@Shared/enums/swipe-actions';
import {SwipeAction} from '@Shared/types/swipe-action';

@Directive({
  selector: '[appSwipe]',
})
export class SwipeDirective {
  @Output() swipeEvent = new EventEmitter<SwipeAction>();
  touchEnabled = matchMedia('(hover: none)').matches;
  private swipeCoord: [number, number] = [0, 0];
  private swipeTime = 0;

  @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent) {
    if (!this.touchEnabled) {
      return;
    }
    this.swipe(event, SwipeActions.start);
  }

  @HostListener('touchend', ['$event']) onTouchEnd(event: TouchEvent) {
    if (!this.touchEnabled) {
      return;
    }
    this.swipe(event, SwipeActions.end);
  }

  swipe(e: TouchEvent, when: SwipeAction): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === SwipeActions.start) {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === SwipeActions.end) {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;
      if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        const swipe = direction[0] < 0 ? SwipeActions.next : SwipeActions.previous;

        this.swipeEvent.emit(swipe);
      }
    }
  }
}
