import {CurrencyPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

const isNumeric = (value: string): boolean => {
  return !isNaN(+value - parseFloat(value));
};

@Pipe({
  name: 'looseCurrency',
})
export class LooseCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value: string | number | null | undefined,
    currencyCode: string,
    symbolDisplay: string,
    digits: string
  ): string | null {
    value = typeof value === 'string' && isNumeric(value) ? +value : value;
    if (typeof value === 'number') {
      return this.currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    } else {
      return value ?? '';
    }
  }
}
