import {Component, Input} from '@angular/core';
import {MatTabNavPanel} from '@angular/material/tabs';
import {TabData} from '@Shared/component-library/interfaces/tab-data';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabData: TabData[] = [];
  @Input() tabPanel?: MatTabNavPanel;
}
