import {Directive, HostListener, Input} from '@angular/core';
import {integerRegex} from '@Shared/constants/regular-expressions';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {
  @Input() includePeriod = false;

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const keyCode = event.code;
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'Delete'];

    if (this.includePeriod) {
      allowedKeys.push('Period');
    }

    if (allowedKeys.includes(keyCode) || integerRegex.test(event.key)) {
      return true;
    } else {
      return false;
    }
  }
}
