import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Subject, delay, filter, switchMap} from 'rxjs';

@Directive({
  selector: '[appObserveVisibility]',
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit, OnChanges {
  @Input() debounceTime = 0;
  @Input() threshold = [0, 0.5, 1];
  @Input() startObserving = false;

  @Output() visible = new EventEmitter<HTMLElement>();

  private observer?: IntersectionObserver;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.createObserver();
  }

  ngAfterViewInit(): void {
    if (this.startObserving) {
      this.startObservingElements();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startObserving.currentValue) {
      this.startObservingElements();
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.complete();
  }

  private isVisible(target: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.isIntersecting);
        observer.disconnect();
      });

      observer.observe(target);
    });
  }

  private createObserver(): void {
    const options = {
      rootMargin: '10px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry): boolean => {
      return entry.intersectionRatio > 0.8;
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      if (isIntersecting(entries[0])) {
        this.subject$.next({entry: entries[0], observer});
        this.observer?.disconnect();
      }
    }, options);
  }

  private startObservingElements(): void {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.element.nativeElement);

    this.subject$
      .pipe(
        delay(this.debounceTime),
        filter(Boolean),
        switchMap(async ({entry, observer}) => {
          const target = entry.target as HTMLElement;
          const isStillVisible = await this.isVisible(target);
          if (isStillVisible) {
            this.visible.emit(target);
            observer.unobserve(target);
          }
        })
      )
      .subscribe();
  }
}
