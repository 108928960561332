import {Injectable} from '@angular/core';
import {ApiEndpointsService} from '@Core/services/api-endpoints/api-endpoints.service';
import {environment} from '@Env/environment';
import {QueryStringParameters} from '@Shared/classes/query-string-parameters/query-string-parameters';

@Injectable({
  providedIn: 'root',
})
export class ApiEndpointsProfileService extends ApiEndpointsService {
  private readonly baseUri = 'pfl/api/v1';
  private readonly baseUriv2 = 'pfl/api/v2';

  constructor() {
    super();
  }

  getCurrentUser(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/me`);
  }

  getUser(accountId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/${encodeURI(accountId)}`);
  }

  getVendorUserByEmailAddress(email: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/vendors?emailAddress=${encodeURI(email)}`);
  }

  getMyProfile(): string {
    return this.createUrl(`${this.baseUri}/users/me/profile`);
  }

  getUserProfile(accountId: string): string {
    return this.createUrl(`${this.baseUri}/users/${accountId}/profile`);
  }

  createUser(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users`);
  }

  createVendorUser(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/vendors`);
  }

  editUser(accountId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/${accountId}`);
  }

  updateUserStatus(accountId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/${accountId}/status`);
  }

  getCentres(tenantId: string, index: number, size: number): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/centres`, (qs: QueryStringParameters) => {
      qs.push('tenantId', tenantId);
      qs.push('index', index);
      qs.push('size', size);
    });
  }

  getUserStatuses(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/references/userstatuses`);
  }

  getRole(roleId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/roles/${encodeURI(roleId)}`);
  }

  deleteRole(roleId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/roles/${encodeURI(roleId)}`);
  }

  createRole(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/roles`);
  }

  editRole(roleId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/roles/${roleId}`);
  }

  getProductEnabledPermissions(productId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/products/${encodeURI(productId)}/permissions`);
  }

  addProfileImage(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/me/images`);
  }

  updateMyProfile(): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/me/profile`);
  }

  getProductFeature(productId: string, featureCode: string, stateId?: string): string {
    return this.createUrlWithQueryParameters(
      `${this.baseUri}/products/${productId}/features`,
      (qs: QueryStringParameters) => {
        qs.push('code', featureCode);
        qs.push('productId', productId);
        if (stateId) {
          qs.push('geographyType', 'State');
          qs.push('geographyId', stateId);
        }
      }
    );
  }

  isProductFeatureEnabled(featureCode: string): string {
    return this.createUrl(`${this.baseUri}/products/${environment.productId}/features/${featureCode}/enabled`);
  }

  upsertCompanyLogo(): string {
    return this.createUrl(`${this.baseUri}/clients/images`);
  }

  activateUser(): string {
    return this.createUrl(`${this.baseUri}/users/me/activate`);
  }

  canCreateMoreUsers(tenantId: string): string {
    return this.createUrlWithQueryParameters(`${this.baseUri}/users/can-create-more`, (qs: QueryStringParameters) => {
      qs.push('tenantId', tenantId);
    });
  }

  hasStripeId(): string {
    return this.createUrl(`${this.baseUriv2}/clients/has-stripe-id`);
  }
}
