import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-roles-centers-error',
  templateUrl: './roles-centers-error.component.html',
  styleUrls: ['./roles-centers-error.component.scss'],
})
export class RolesCentersErrorComponent {
  @Input() title = '';
  @Input() label = '';
  @Output() infoButtonClick = new EventEmitter<string>();
  @Output() addButtonClick = new EventEmitter<string>();
}
