// eslint-disable-next-line no-shadow
export enum Icons {
  add = 'add',
  archive = 'inventory_2',
  apartment = 'apartment',
  cancel = 'cancel',
  close = 'close',
  comment = 'message',
  document = 'description',
  dollar = 'attach_money',
  done = 'done',
  download = 'download',
  dropdown = 'more_vert',
  fullscreen = 'fullscreen',
  home = 'home',
  leftArrow = 'chevron_left',
  list = 'list',
  location = 'location_on',
  mail = 'mail_outline',
  messageDisable = 'speaker_notes_off',
  messageTick = 'mark_chat_read',
  person = 'person',
  preview = 'preview',
  recall = 'undo',
  rightArrow = 'chevron_right',
  search = 'search',
  domain = 'domain',
  house = 'house',
}
