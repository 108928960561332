import {Directive, ElementRef} from '@angular/core';
import {DomEvent} from 'leaflet';

@Directive({
  selector: '[appDisableMapPropagation]',
})
export class DisableMapPropagationDirective {
  constructor(element: ElementRef) {
    DomEvent.disableScrollPropagation(element.nativeElement);
    DomEvent.disableClickPropagation(element.nativeElement);
  }
}
