import {CurrencyPipe} from '@angular/common';
import {Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

import {parseNumber} from '@Shared/helpers/number-helpers/number-helpers';

@Directive({
  selector: '[appCurrencyInput]',
})
export class CurrencyInputDirective implements OnInit {
  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    private currencyPipe: CurrencyPipe
  ) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (value) {
      this.format(value);
    }
  }

  ngOnInit() {
    this.format(this.el.nativeElement.value);
  }

  format(value: string) {
    const numberFormat = parseNumber(value);

    const currency = this.currencyPipe.transform(numberFormat, '', 'symbol-narrow', '1.0-0');

    this.renderer.setProperty(this.el.nativeElement, 'value', currency);
  }
}
