<button
  mat-flat-button
  color="primary"
  class="select__button"
  [matMenuTriggerFor]="menu"
>
  <mat-icon
    fontSet="material-icons-outlined"
    class="button__icon"
    >{{ buttonIcon }}</mat-icon
  >
  <span>{{ label }}: </span>
  <span class="selected-option">{{ selectedOptions.length || 'Any' }}</span>
</button>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="$event.stopPropagation()"
  >
    <mat-checkbox
      (change)="onAllChecked($event)"
      [(ngModel)]="allChecked"
      >All
    </mat-checkbox>
  </button>
  @for (option of options; track option) {
    <button
      mat-menu-item
      (click)="$event.stopPropagation()"
    >
      <mat-checkbox
        class="select__checkbox"
        (change)="onChange(option)"
        [(ngModel)]="option.checked"
        >{{ option.label }}</mat-checkbox
      >
    </button>
  }
</mat-menu>
