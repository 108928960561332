/* eslint-disable @typescript-eslint/naming-convention */
export enum ViewType {
  Card = 'Card',
  List = 'List',
  Map = 'Map',
  Summary = 'Summary',
  Users = 'Users',
  Centres = 'Centres',
  SuburbSearch = 'Location',
  RadiusSearch = 'Radius',
  Sales = 'Sales',
  Rental = 'Rental',
  All = 'All',
  Zones = 'Zones',
}
