import {Pipe, PipeTransform} from '@angular/core';
import {isPermission} from '@Shared/component-library/type-guards/permission-type-guard';
import {isRole} from '@Shared/component-library/type-guards/role-type-guard';
import {isUserInfo} from '@Shared/component-library/type-guards/user-profile-type-guard';
import {PropertyDocument} from '@Shared/interfaces/property-document';
import {isPropertyDocument} from '@Shared/type-guards/property-document-type-guard';

const MAX_LENGTH = 5;

@Pipe({
  name: 'formatTooltipList',
})
export class FormatTooltipListPipe implements PipeTransform {
  transform(data: unknown): string[] | unknown {
    if (Array.isArray(data)) {
      if (isPropertyDocument(data[0])) {
        return this.formatToolTipList(data as PropertyDocument[]);
      }

      if (isPermission(data[0])) {
        return this.formatMaxListLength(data.map((permission) => permission.name));
      }

      if (isRole(data[0])) {
        return this.formatMaxListLength(data.map((role) => role.roleName));
      }

      if (isUserInfo(data[0])) {
        return this.formatMaxListLength(data.map((user) => user.fullName));
      }
    }
    return data;
  }

  formatToolTipList(documents: PropertyDocument[]): string[] {
    return this.formatMaxListLength(
      documents.map((document) => `${document.documentName} - ${new Date(document.createdAt).toDateString()}`)
    );
  }

  formatMaxListLength(list: string[]): string[] {
    if (list.length > MAX_LENGTH) {
      return list.slice(0, MAX_LENGTH).concat(`+${list.length - MAX_LENGTH} more`);
    } else {
      return list;
    }
  }
}
