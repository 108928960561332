<div class="card__actions">
  @for (action of actions; track action) {
    <app-icon-button
      [buttonIcon]="action.icon"
      [matTooltip]="action.tooltip ?? ''"
      (buttonClicked)="onClick(action)"
      iconColor="primary"
    ></app-icon-button>
  }
</div>
