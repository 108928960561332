import {InjectionToken} from '@angular/core';
import {FormErrors} from '@Shared/interfaces/form-errors';

export const defaultErrors: FormErrors = {
  required: () => `This field is required`,
  min: ({min}: {min: number}) => `Must be greater than ${min}`,
  max: ({max}: {max: number}) => `Must be less than ${max}`,
  maxlength: ({requiredLength}: {requiredLength: number}) => `Exceeds maximum length of ${requiredLength} characters`,
  minlength: ({requiredLength}: {requiredLength: number}) => `Minimum length is ${requiredLength} characters`,
  greaterOrEqual: () => `Value is less than min`,
  lessOrEqual: () => `Value is greater than max`,
  whitespace: () => `Value cannot start with whitespace`,
  fileSize: ({maxFileSize}) => `File size exceeds ${maxFileSize}MB`,
  invalidMimeType: ({name}) => `File type for ${name} is not allowed`,
  email: () => `Not a valid email`,
  notUnique: () => `Not a unique name`,
  hyphenApostropheOrSpace: () => 'Must not start or end with hyphen, apostrophe or space',
  doesntContainValue: () => 'Must select value from list',
  alreadyExists: () => 'Filename already exists',
  year: () => `Please enter a valid year`,
  forbiddenCharacters: () => `Input contains forbidden characters`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
