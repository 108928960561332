<button
  class="button"
  [attr.aria-label]="ariaLabel"
>
  <mat-icon
    fontSet="material-icons-outlined"
    class="icon"
    [ngClass]="{'icon-primary': iconColor === 'primary', 'icon-warning': iconColor === 'warning'}"
    (click)="onClick()"
    >{{ buttonIcon }}</mat-icon
  >
</button>
