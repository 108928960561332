<div
  class="chip"
  [ngStyle]="{'border-color': backgroundColor}"
>
  <mat-icon [ngStyle]="{color: backgroundColor}">{{ icon }}</mat-icon>
  @if (unit) {
    <p class="chip__text">
      {{ text }} <span>{{ unit }}</span>
    </p>
  } @else {
    <p class="chip__text">
      {{ text }}
    </p>
  }
</div>
