import {Component, Input, OnInit} from '@angular/core';
import {ResultStatus} from '@Shared/component-library/enums/result-status';

@Component({
  selector: 'app-card-row-item',
  templateUrl: './card-row-item.component.html',
  styleUrls: ['./card-row-item.component.scss'],
})
export class CardRowItemComponent implements OnInit {
  @Input() rowName = '';
  @Input() rowData: string | string[] | unknown[] | undefined = '';
  @Input() rowIcon: string | undefined = '';
  @Input() showAccountStatus: boolean | undefined = false;
  @Input() showTooltipList: boolean | undefined = false;

  state = ResultStatus.success;

  ngOnInit(): void {
    if ((Array.isArray(this.rowData) && this.rowData.length <= 0) || this.rowData !== 'Active') {
      this.state = ResultStatus.warn;
    }
  }
}
