import {Component, Input} from '@angular/core';
import {UserInfo} from '@Shared/component-library/classes/user-info';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent {
  @Input() userInfo?: UserInfo;
}
