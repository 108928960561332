<div class="search__form-group">
  <mat-form-field
    [formGroup]="formGroupParent"
    appearance="outline"
    class="search-field"
  >
    <mat-icon
      matPrefix
      class="icon"
      >search</mat-icon
    >
    <input
      aria-label="Search"
      matInput
      [formControlName]="controlName"
      [placeholder]="placeholder"
      type="text"
    />
  </mat-form-field>
  @if (showButtons) {
    <app-icon-button
      class="clear-button"
      [ngClass]="{'clear-button--secondary': !showSearchButton}"
      (buttonClicked)="onClear()"
      buttonIcon="close"
    ></app-icon-button>
  }
  @if (showButtons && showSearchButton) {
    <button
      class="search__button"
      (click)="onSubmit()"
    >
      Search
    </button>
  }
</div>
