/* eslint-disable @typescript-eslint/naming-convention */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatDataArray',
})
export class FormatDataArrayPipe implements PipeTransform {
  nameMap = {
    Roles: 'Role',
    Assigned: 'Role',
    'Home Centre of': 'User',
    'Access to Centre': 'User',
    'Assigned To': 'User',
    'Total Users': 'User',
    Permissions: 'Permission',
    Contains: 'Permission',
    Documents: 'Document',
    numberOfAssignedUsers: 'User',
  } as Record<string, string>;

  transform(item: string | number | string[] | unknown[], element: string): string {
    if (!this.nameMap[element]) {
      return item?.toString();
    }

    if (Array.isArray(item)) {
      return this.getText(item.length, element);
    }

    if (typeof item === 'number') {
      return this.getText(item as number, element);
    }

    if (typeof item === 'string' && (item as string).trim() && !isNaN(Number(item))) {
      return this.getText(Number(item), element);
    }

    return item?.toString();
  }

  private getText(num: number, element: string): string {
    let pluralText = '';

    if (num !== 1) {
      pluralText = 's';
    }
    return `${num} ${this.nameMap[element]}${pluralText}`;
  }
}
