import {Component, Input} from '@angular/core';
import {CardData} from '@Shared/component-library/interfaces/card-data';

@Component({
  selector: 'app-card-row-group',
  templateUrl: './card-row-group.component.html',
})
export class CardRowGroupComponent {
  @Input() cardData?: CardData[];
}
