import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-heading-block',
  templateUrl: './heading-block.component.html',
  styleUrls: ['./heading-block.component.scss'],
})
export class HeadingBlockComponent {
  @Input() heading = '';
  @Input() buttonLabel = '';
  @Input() isActionButtonDisabled = false;

  @Output() infoButtonClick = new EventEmitter();
  @Output() buttonClick = new EventEmitter();
}
