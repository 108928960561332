<form [formGroup]="form">
  @for (field of formFields; track field) {
    <app-form-field
      [formGroupParent]="form"
      [controlName]="field.controlName"
      [label]="field.label"
      [type]="field.type"
    ></app-form-field>
  }
</form>
