/* eslint-disable @typescript-eslint/member-ordering */
import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
  selector: '[appThrottleClick]',
  standalone: true,
})
export class ThrottleClickDirective implements OnInit, OnDestroy {
  @Input() throttleDuration = 500;
  @Output() throttleClick = new EventEmitter();
  clicks = new Subject();
  subscription!: Subscription;

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(throttleTime(this.throttleDuration))
      .subscribe((e) => this.throttleClick.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
