import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultStatus} from '@Shared/component-library/enums/result-status';

@Component({
  selector: 'app-text-with-icon',
  templateUrl: './text-with-icon.component.html',
  styleUrls: ['./text-with-icon.component.scss'],
})
export class TextWithIconComponent implements OnInit {
  @Input() text?: string | null;
  @Input() icon = '';
  @Input() state: ResultStatus = ResultStatus.success;
  @Input() isLink? = false;
  @Input() boldText? = false;
  @Input() toolTipText?: string;

  @Output() linkClick = new EventEmitter();

  notAvailableText = '-';

  ngOnInit(): void {
    if (this.text === '') {
      this.text = this.notAvailableText;
    }
  }

  onLinkClick() {
    this.linkClick.emit();
  }
}
