import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ErrorHandlerService} from '@Core/services/error-handlers/error-handler.service';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnInit, OnDestroy {
  errorText?: string | null;
  class = '';
  controlName: string | undefined = '';

  private readonly destroy$ = new Subject<void>();

  constructor(private errorHandlerService: ErrorHandlerService) {}

  @Input() set text(value: string | null) {
    if (value !== this.errorText) {
      this.errorText = value;
    }
  }

  @Input() set customClass(value: string) {
    if (value !== this.class) {
      this.class = value;
    }
  }

  ngOnInit() {
    this.errorHandlerService.resetFormError$.pipe(takeUntil(this.destroy$)).subscribe((controlName) => {
      if (controlName === this.controlName) {
        this.errorText = null;
      }
    });

    this.errorHandlerService.resetAllFormsError$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorText = null;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
