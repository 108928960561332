<div class="row">
  <p class="row__heading">{{ rowName }}</p>
  @if (rowData && rowIcon && !showTooltipList) {
    <app-text-with-icon
      [text]="rowData | formatDataArray: rowName"
      [icon]="rowIcon"
    ></app-text-with-icon>
  }

  @if (rowData && rowIcon && showTooltipList) {
    <app-text-with-icon
      [text]="rowData | formatDataArray: rowName"
      [icon]="rowIcon"
      [toolTipText]="rowData | formatTooltipList | tooltipList"
    ></app-text-with-icon>
  }

  @if (showAccountStatus) {
    <div
      class="status"
      [class.status--active]="state === 'success'"
      [class.status--deactivated]="state === 'warn'"
    >
      <p>{{ rowData }} Account</p>
    </div>
  }
</div>
