<nav
  mat-tab-nav-bar
  mat-stretch-tabs
  class="tab__bar"
  [tabPanel]="tabPanel"
>
  @for (tab of tabData; track tab) {
    <a
      mat-tab-link
      [routerLink]="tab.link"
      routerLinkActive
      [routerLinkActiveOptions]="{exact: true}"
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      <div class="tab__label">
        @if (tab.icon) {
          <mat-icon
            [color]="rla.isActive ? 'primary' : ''"
            class="tab__icon"
          >
            {{ tab.icon }}
          </mat-icon>
        }
        {{ tab.title }}
      </div>
    </a>
  }
</nav>
