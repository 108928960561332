import {PropertyID} from '@Shared/types/property-id';
import {Vendor} from '@Shared/interfaces/vendor';
import {CmaStatusType} from '@Shared/enums/cma-status-type';
import {CmaComparableProperty} from '@Shared/interfaces/cma-comparable-property';
import {PropertyDetails} from '@Shared/interfaces/property-details';
import {CmaId} from '@Shared/types/cma-id';
import {CmaChanged} from '@Shared/interfaces/events/cma-changed';

import moment from 'moment';
import {Search} from '../search/search';
import {CmaCustomImage} from '@Shared/interfaces/cma-custom-image';
import {EditableField} from '@Shared/interfaces/editable-field';
import {CmaEditedField} from '@Shared/interfaces/cma-edited-field';
import {Observable, of} from 'rxjs';
import {CmaTemplate} from '@Shared/interfaces/cma-template';
import {CmaType} from '@Shared/enums/cma-type';
import {CmaStatusChange} from '@Shared/interfaces/cma-status-change';
import {PropertyDocument} from '@Shared/interfaces/property-document';
import {CmaTemplatePage} from '@Shared/interfaces/cma-template-page';
import {CmaAgent} from '@Shared/interfaces/cma-agent';
import {CmaFilter} from '@Shared/classes/cma-filter/CmaFilter';
import {CmaFilterType} from '@Shared/enums/CmaFilterType';
import {SearchFilter} from '../search-filter';
import {Sort} from '@angular/material/sort';
import {isDefined} from '@Shared/type-guards/is-defined-type-guard';

export class ComparativeMarketAnalysis {
  id: CmaId = '';
  cmaType!: CmaType;
  createdByName = '';
  preparedBy?: CmaAgent;
  comparableProperties: CmaComparableProperty[] = [];
  cmaCustomImages: CmaCustomImage[] = [];
  pages: CmaTemplatePage[] = [];
  enableVendorView = false;
  estimatedHighPrice = 0;
  estimatedLowPrice = 0;
  preparedForName = '';
  preparedForPrefix? = '';
  preparedForToggled = false;
  subjectPropertyCommentary = '';
  subjectPropertyCommentaryToggled = false;
  priceRange = '';
  priceRangeToggled = false;
  totalCommissionHighRange = '';
  totalCommissionLowRange = '';
  commissionSchedule = '';
  finalisedDocumentUri?: string;
  filters: CmaFilter[] = [];
  name = '';
  targetPropertyId: PropertyID = '';
  vendors: Vendor[] = [];
  status = CmaStatusType.Draft;
  templateId?: string;
  cmaEditedPropertyFields: CmaEditedField[] = [];
  editablePropertyFields: EditableField[] = [];
  cmaStatusChanges: CmaStatusChange[] = [];
  documents: PropertyDocument[] = [];
  lastModified?: Date;
  webPreviewUri?: string;
  statisticsEmailEnabled?: boolean | null = null;

  constructor(cma?: Partial<ComparativeMarketAnalysis>) {
    if (cma) {
      Object.assign(this, {...cma});
      this.filters = this.filters.map((item) => new CmaFilter(item));

      if (!isDefined(cma.preparedForPrefix)) {
        this.setDefaultPreparedForPrefix();
      }
    }
  }

  get hasVendor(): boolean {
    return this.vendors.length > 0;
  }

  static getDefaultName(propertyDetails: PropertyDetails, today: Date): string {
    const timestamp = moment(today).format('YYYYMMDDHHmmss');

    return `${propertyDetails.address} - ${timestamp}`;
  }

  static containsPropertiesWithoutComment(properties: CmaComparableProperty[]): boolean {
    return properties.some((property) => !property.comment && !property.whenDeleted);
  }

  private static addEditedPropertyFieldToCma(
    editedField: CmaEditedField,
    editedFields: CmaEditedField[]
  ): CmaEditedField[] {
    const newEditedFields = [...editedFields];
    const editedFieldExists = this.findExistingEditedField(editedField, editedFields);

    if (editedFieldExists) {
      newEditedFields.splice(newEditedFields.indexOf(editedFieldExists), 1);
    }

    newEditedFields.push(editedField);

    return newEditedFields;
  }

  private static findExistingEditedField(
    editedField: CmaEditedField,
    editedFields: CmaEditedField[]
  ): CmaEditedField | undefined {
    return editedFields.find(
      (editedFieldToCheck) =>
        editedFieldToCheck.propertyId.toUpperCase() === editedField.propertyId.toUpperCase() &&
        editedFieldToCheck.editablePropertyFieldId.toUpperCase() === editedField.editablePropertyFieldId.toUpperCase()
    );
  }

  private setDefaultPreparedForPrefix() {
    this.preparedForPrefix = 'Dear';
  }

  addVendor(vendor: Vendor): void {
    this.vendors.push(vendor);
  }

  removeVendor(index: number): void {
    this.vendors.splice(index, 1);
  }

  addEditedPropertyField(editedField: CmaEditedField): void {
    this.cmaEditedPropertyFields = ComparativeMarketAnalysis.addEditedPropertyFieldToCma(
      editedField,
      this.cmaEditedPropertyFields
    );
  }

  applyChanges(cmaChangedEvent: CmaChanged): boolean {
    let wasChanged = false;
    wasChanged = this.applyEstimatedHighPriceChange(cmaChangedEvent.estimatedHighPrice) || wasChanged;
    wasChanged = this.applyEstimatedLowPriceChange(cmaChangedEvent.estimatedLowPrice) || wasChanged;
    wasChanged = this.applyPriceRangeChange(cmaChangedEvent.priceRange) || wasChanged;
    wasChanged = this.applyNameChange(cmaChangedEvent.name) || wasChanged;
    wasChanged = this.applyTotalCommissionHighRangeChange(cmaChangedEvent.totalCommissionHighRange) || wasChanged;
    wasChanged = this.applyTotalCommissionLowRangeChange(cmaChangedEvent.totalCommissionLowRange) || wasChanged;
    wasChanged = this.applyCommissionScheduleChange(cmaChangedEvent.commissionSchedule) || wasChanged;
    wasChanged = this.applyTemplateChange(cmaChangedEvent.template) || wasChanged;
    wasChanged = this.applyPreparedByChange(cmaChangedEvent.preparedBy) || wasChanged;
    wasChanged = this.applyPriceRangeToggledChange(cmaChangedEvent.priceRangeToggled) || wasChanged;
    wasChanged = this.applyPreparedForPrefixChange(cmaChangedEvent.preparedForPrefix) || wasChanged;
    wasChanged = this.applyPreparedForNameChange(cmaChangedEvent.preparedForName) || wasChanged;
    wasChanged = this.applyPreparedForToggledChange(cmaChangedEvent.preparedForToggled) || wasChanged;
    wasChanged = this.applyStatisticsEmailEnabledChange(cmaChangedEvent.statisticsEmailEnabled) || wasChanged;
    wasChanged = this.applyPropertyCommentaryChange(cmaChangedEvent.subjectPropertyCommentary) || wasChanged;
    wasChanged =
      this.applyPropertyCommentaryToggledChange(cmaChangedEvent.subjectPropertyCommentaryToggled) || wasChanged;

    return wasChanged;
  }

  finalise() {
    this.status = CmaStatusType.Finalised;
  }

  getCustomImageUri(
    aerialImageUri$: Observable<string> | undefined,
    propertyId: PropertyID
  ): Observable<string> | undefined {
    if (this.cmaCustomImages.length === 0) {
      return aerialImageUri$;
    }
    const customUri = this.cmaCustomImages.find(
      (r) => r.propertyId.toUpperCase() === propertyId.toUpperCase()
    )?.imageUri;

    return customUri ? of(customUri) : aerialImageUri$;
  }

  getEditedPropertyFields(propertyId: PropertyID): CmaEditedField[] {
    return this.cmaEditedPropertyFields.filter((r) => r.propertyId.toUpperCase() === propertyId.toUpperCase());
  }

  updateStatus(status: CmaStatusType) {
    this.status = status;
  }

  getFilter(filterType: CmaFilterType): CmaFilter | undefined {
    return this.filters.find((x) => x.filterType === filterType);
  }

  updateFilter(filterType: CmaFilterType, newSearch: Search) {
    const filteredItems = this.getFilter(filterType) as CmaFilter;
    filteredItems.search = new Search(newSearch);
  }

  addPropertyToFilter(filterType: CmaFilterType, property: CmaComparableProperty) {
    const filteredItems = this.getFilter(filterType) as CmaFilter;
    filteredItems.search?.filters.propertyIdentifiers.push(property.propertyId);
  }

  initialiseFilter(filterType: CmaFilterType, searchFilter?: SearchFilter, defaultSort?: Sort) {
    let filter = this.getFilter(filterType);
    if (!filter) {
      filter = new CmaFilter();
      filter.search = searchFilter ? new Search({filters: searchFilter}) : new Search();
      filter.filterType = filterType;
      this.filters?.push(filter);
    } else {
      filter.initialiseSearch();
    }
    if (filter.search && !filter.search.sort) {
      filter.search.sort = defaultSort;
    }
    return filter.search;
  }

  removePropertyFromFilter(filterType: CmaFilterType, propertyId: PropertyID) {
    const filteredItems = this.getFilter(filterType) as CmaFilter;
    if (filteredItems.search) {
      filteredItems.search.filters.propertyIdentifiers = filteredItems.search.filters.propertyIdentifiers.filter(
        (current) => current !== propertyId
      );
    }
  }

  getVendorList() {
    const vendors = [...this.vendors];
    const sharedWith = this.documents?.reduce((acc, doc) => {
      if (doc.sharedWith) {
        acc.push(...doc.sharedWith);
      }
      return acc;
    }, [] as Vendor[]);

    if (sharedWith) {
      vendors.push(...sharedWith);
    }

    const uniqueVendors = vendors.filter((vendor, index, self) => {
      return index === self.findIndex((v) => v.firstName === vendor.firstName && v.lastName === vendor.lastName);
    });

    return uniqueVendors;
  }

  removeCustomImage(id: string) {
    this.cmaCustomImages = this.cmaCustomImages.filter((r) => r.id !== id);
  }

  private applyEstimatedHighPriceChange(estimatedHighPrice?: number): boolean {
    let wasChanged = false;
    if (estimatedHighPrice && estimatedHighPrice !== this.estimatedHighPrice) {
      this.estimatedHighPrice = estimatedHighPrice;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyEstimatedLowPriceChange(estimatedLowPrice?: number): boolean {
    let wasChanged = false;
    if (estimatedLowPrice && estimatedLowPrice !== this.estimatedLowPrice) {
      this.estimatedLowPrice = estimatedLowPrice;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyPriceRangeChange(priceRange?: string): boolean {
    let wasChanged = false;
    if (isDefined(priceRange) && priceRange !== this.priceRange) {
      this.priceRange = priceRange ?? '';
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyPriceRangeToggledChange(priceRangeToggled?: boolean): boolean {
    let wasChanged = false;
    if (isDefined(priceRangeToggled) && priceRangeToggled !== this.priceRangeToggled) {
      this.priceRangeToggled = priceRangeToggled;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyNameChange(name?: string): boolean {
    let wasChanged = false;
    if (name && name !== this.name) {
      this.name = name;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyTotalCommissionHighRangeChange(totalCommissionHighRange?: string): boolean {
    let wasChanged = false;
    if (totalCommissionHighRange && totalCommissionHighRange !== this.totalCommissionHighRange) {
      this.totalCommissionHighRange = totalCommissionHighRange;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyTotalCommissionLowRangeChange(totalCommissionLowRange?: string): boolean {
    let wasChanged = false;
    if (totalCommissionLowRange && totalCommissionLowRange !== this.totalCommissionLowRange) {
      this.totalCommissionLowRange = totalCommissionLowRange;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyCommissionScheduleChange(commissionSchedule?: string): boolean {
    let wasChanged = false;
    if (commissionSchedule && commissionSchedule !== this.commissionSchedule) {
      this.commissionSchedule = commissionSchedule;
      wasChanged = true;
    }

    return wasChanged;
  }

  private applyTemplateChange(template?: CmaTemplate | null): boolean {
    let wasChanged = false;
    if (template && template.id !== this.templateId) {
      this.templateId = template.id;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPreparedByChange(preparedBy?: CmaAgent | null): boolean {
    let wasChanged = false;
    if (preparedBy && preparedBy.accountId !== this.preparedBy?.accountId) {
      this.preparedBy = preparedBy;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPreparedForNameChange(name?: string): boolean {
    let wasChanged = false;
    if (isDefined(name) && name !== this.preparedForName) {
      this.preparedForName = name;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPreparedForPrefixChange(prefix?: string): boolean {
    let wasChanged = false;
    if (isDefined(prefix) && prefix !== this.preparedForPrefix) {
      this.preparedForPrefix = prefix;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPreparedForToggledChange(preparedForToggled?: boolean): boolean {
    let wasChanged = false;
    if (isDefined(preparedForToggled) && preparedForToggled !== this.preparedForToggled) {
      this.preparedForToggled = preparedForToggled;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPropertyCommentaryChange(subjectPropertyCommentary?: string): boolean {
    let wasChanged = false;
    if (isDefined(subjectPropertyCommentary) && subjectPropertyCommentary !== this.subjectPropertyCommentary) {
      this.subjectPropertyCommentary = subjectPropertyCommentary;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyPropertyCommentaryToggledChange(subjectPropertyCommentaryToggled?: boolean): boolean {
    let wasChanged = false;
    const hasSubjectPropertyCommentaryToggleChanged =
      isDefined(subjectPropertyCommentaryToggled) &&
      subjectPropertyCommentaryToggled !== this.subjectPropertyCommentaryToggled;
    if (hasSubjectPropertyCommentaryToggleChanged) {
      this.subjectPropertyCommentaryToggled = subjectPropertyCommentaryToggled;
      wasChanged = true;
    }
    return wasChanged;
  }

  private applyStatisticsEmailEnabledChange(statisticsEnabled?: boolean | null): boolean {
    let wasChanged = false;
    if (isDefined(statisticsEnabled) && statisticsEnabled !== this.statisticsEmailEnabled) {
      this.statisticsEmailEnabled = statisticsEnabled;
      wasChanged = true;
    }
    return wasChanged;
  }
}
