import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

export interface FormField {
  label: string;
  controlName: string;
  type: string;
  icon?: string;
}

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() formFields: FormField[] = [];
}
