<div
  class="icon__container"
  [ngClass]="{'icon__container--warning': state === 'warn'}"
>
  @if (state === 'success' && icon) {
    <mat-icon
      fontSet="material-icons-outlined"
      class="icon"
      >{{ icon }}</mat-icon
    >
  }

  @if (state === 'warn') {
    <mat-icon
      fontSet="material-icons-outlined"
      class="icon--warning"
      >error_outline</mat-icon
    >
  }
  @if (isLink) {
    <button
      class="link"
      (click)="onLinkClick()"
      [matTooltip]="toolTipText ?? ''"
    >
      <p
        class="icon__text"
        [ngClass]="{'icon__text--bold': boldText}"
      >
        {{ text ?? notAvailableText }}
      </p>
    </button>
  } @else {
    <p
      class="icon__text"
      [ngClass]="{'icon__text--bold': boldText, icon__text__nowrap: icon}"
      [matTooltip]="toolTipText ?? ''"
      [matTooltipClass]="'tooltip-list'"
    >
      {{ text ?? notAvailableText }}
    </p>
  }
</div>
