import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss'],
})
export class WarningBannerComponent {
  @Input() text = '';
  @Output() closeButtonClicked = new EventEmitter();
}
