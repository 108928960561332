<div class="heading__block">
  <div class="heading__with-icon">
    <h1 class="heading">{{ heading }}</h1>
    <button
      mat-icon-button
      (click)="infoButtonClick.emit()"
    >
      <mat-icon
        class="icon icon__help"
        fontSet="material-icons-outlined"
        matTooltip="Open Help Centre"
        >help</mat-icon
      >
    </button>
  </div>

  @if (buttonLabel && buttonClick) {
    <button
      mat-flat-button
      color="primary"
      class="heading__button"
      (click)="buttonClick.emit()"
      [disabled]="isActionButtonDisabled"
    >
      <mat-icon
        class="icon icon__add"
        fontSet="material-icons-outlined"
        >add_circle</mat-icon
      >
      {{ buttonLabel }}
    </button>
  }
</div>
