import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {DesignSystemIcon} from '@Shared/component-library/enums/design-system-icon';
import {SelectButtonOption} from '@Shared/component-library/interfaces/select-button-option';
import {Observable, takeUntil} from 'rxjs';

@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
})
export class SelectButtonComponent implements OnInit, OnDestroy {
  @Input() buttonIcon = DesignSystemIcon.filter;
  @Input() label = '';
  @Input() options: SelectButtonOption[] = [];
  @Input() resetFiltersObservable?: Observable<void>;
  @Output() selectedValue = new EventEmitter<string[]>();
  @Output() filterReset = new EventEmitter();

  selectedOptions: string[] = [];
  allChecked = false;

  private readonly destroy$ = new EventEmitter<void>();

  ngOnInit() {
    if (this.resetFiltersObservable) {
      this.resetFiltersObservable.pipe(takeUntil(this.destroy$)).subscribe(() => this.resetFilters());
    }
  }

  resetFilters() {
    this.allChecked = false;
    this.options.forEach((option) => {
      option.checked = false;
    });
    this.selectedOptions = [];
    this.filterReset.emit();
  }

  onChange(option: SelectButtonOption) {
    if (option.checked) {
      this.selectedOptions = [...this.selectedOptions, option.value];
    } else {
      this.selectedOptions = this.selectedOptions.filter((value) => value !== option.value);
    }

    this.selectedValue.emit(this.selectedOptions);
  }

  onAllChecked(event: MatCheckboxChange) {
    this.allChecked = event.checked;
    this.options.forEach((option) => {
      option.checked = this.allChecked;
    });
    this.selectedOptions = this.allChecked ? this.options.map((option) => option.value) : [];
    this.selectedValue.emit(this.selectedOptions);
  }

  ngOnDestroy() {
    this.destroy$.emit();
    this.destroy$.complete();
  }
}
