@if (column) {
  @switch (true) {
    @case (column.isDate) {
      <app-text-with-icon
        [text]="tableElement | formatDataArray: column.name | date: dateFormat"
        [icon]="column.icon ?? ''"
        [boldText]="column.boldText"
      >
      </app-text-with-icon>
    }
    @case (column.isCurrency) {
      <app-text-with-icon
        [text]="tableElement | formatDataArray: column.name | looseCurrency: '' : 'symbol-narrow' : '1.0-0'"
        [icon]="column.icon ?? ''"
        [boldText]="column.boldText"
      >
      </app-text-with-icon>
    }
    @case (column.showChip && column.showTooltipList) {
      <app-chip
        [matTooltip]="tableElement | formatTooltipList | tooltipList"
        [matTooltipClass]="'tooltip-list'"
        [icon]="column.icon"
        [text]="tableElement | formatDataArray: column.name"
      ></app-chip>
    }
    @case (column.showChip && !column.showTooltipList) {
      <app-chip
        [icon]="column.icon"
        [text]="tableElement | formatDataArray: column.name"
      ></app-chip>
    }
    @case (column.showTooltipList && !column.showChip) {
      <app-text-with-icon
        [text]="tableElement | formatDataArray: column.name"
        [toolTipText]="tableElement | formatTooltipList | tooltipList"
        [icon]="column.icon ?? ''"
        [isLink]="column.isLink"
        [boldText]="column.boldText"
        (linkClick)="onLinkClick()"
      ></app-text-with-icon>
    }
    @default {
      <app-text-with-icon
        [text]="tableElement | formatDataArray: column.name"
        [icon]="column.icon ?? ''"
        [isLink]="column.isLink"
        [boldText]="column.boldText"
        [toolTipText]="column.toolTipText"
        (linkClick)="onLinkClick()"
      ></app-text-with-icon>
    }
  }
}
