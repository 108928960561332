import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'tooltipList',
})
export class TooltipListPipe implements PipeTransform {
  transform(lines: string[] | unknown): string {
    if (!Array.isArray(lines)) {
      return '';
    }

    let list = '';

    lines.forEach((line) => {
      list += '• ' + line + '\n';
    });
    return list;
  }
}
