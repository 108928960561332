<div class="container">
  <div class="title">
    <mat-icon
      fontSet="material-icons-outlined"
      class="icon"
      >error</mat-icon
    >
    <h3>{{ title }}</h3>
  </div>
  <div class="description">
    <ng-content></ng-content>
  </div>
  <div class="actions">
    <button
      mat-flat-button
      color="primary"
      class="action__button--primary"
      (click)="addButtonClick.emit()"
    >
      Add {{ label }}
    </button>
  </div>
</div>
