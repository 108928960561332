import {Component, Input, Output, EventEmitter} from '@angular/core';
import {ActionColumn} from '@Shared/component-library/interfaces/action-column';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss'],
})
export class CardActionsComponent {
  @Input() actions: ActionColumn[] = [];
  @Output() emitAction = new EventEmitter<string>();

  onClick(action: ActionColumn) {
    this.emitAction.emit(action.name);
  }
}
