import {Directive, NgZone, OnDestroy} from '@angular/core';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {fromEvent, Subject, takeUntil} from 'rxjs';

@Directive({
  selector: '[appAutoCompletePosition]',
  standalone: true,
})
export class AutoCompletePositionDirective implements OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(
    public matAutoCompleteTrigger: MatAutocompleteTrigger,
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document, 'scroll', {capture: true})
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onScroll();
        });
    });
  }

  onScroll() {
    if (!this.matAutoCompleteTrigger) {
      return;
    }

    if (this.matAutoCompleteTrigger.panelOpen) {
      this.matAutoCompleteTrigger.updatePosition();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
