import {Component, Input, OnInit} from '@angular/core';
import {Icons} from '@Shared/enums/icons';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent implements OnInit {
  @Input() text: string | null = '';
  @Input() icon? = '';
  @Input() unit? = '';

  backgroundColor = 'var(--Primary)';

  ngOnInit(): void {
    this.backgroundColor = this.getBackgroundColor(this.text);
    if (!this.icon) {
      this.icon = this.getIcon(this.text);
    }
  }

  getBackgroundColor(status: string | null): string {
    switch (status) {
      case 'Listed':
        return 'var(--Collection-3)';
      case 'Sold':
        return 'var(--Collection-1)';
      case 'Archived':
        return 'var(--Collection-4)';
      case 'CMA':
        return 'var(--Collection-2)';
      default:
        return 'var(--Primary)';
    }
  }

  getIcon(status: string | null): string {
    switch (status) {
      case 'Listed':
        return Icons.list;
      case 'Sold':
        return Icons.dollar;
      case 'Archived':
        return Icons.archive;
      default:
        return Icons.document;
    }
  }
}
