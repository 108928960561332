import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholder = '';
  @Input() formGroupParent = new UntypedFormGroup({});
  @Input() controlName = '';
  @Input() showSearchButton = false;
  @Output() emitSearchEvent = new EventEmitter<string>();

  control: UntypedFormControl = new UntypedFormControl();
  showButtons = false;

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.emitSearchEvent.emit(this.control.value);
    }
  }

  ngOnInit(): void {
    this.control = this.formGroupParent.get(this.controlName) as UntypedFormControl;
    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.showButtons = true;
      }
    });
  }

  onClear() {
    this.control.patchValue('');
    this.showButtons = false;
    this.emitSearchEvent.emit(this.control.value);
  }

  onSubmit() {
    this.emitSearchEvent.emit(this.control.value);
  }
}
