<mat-accordion multi>
  <mat-expansion-panel
    class="expansion-panel"
    expanded="true"
  >
    <mat-expansion-panel-header class="expansion-panel__header">
      <mat-panel-title>
        <h2 class="quantum-card-heading">{{ title }}</h2>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>
