import {Component, EventEmitter, Input, Output} from '@angular/core';
import {environment} from '@Env/environment';
import {TableColumn} from '@Shared/component-library/interfaces/table-column';

@Component({
  selector: 'app-table-cell-data',
  templateUrl: './table-cell-data.component.html',
})
export class TableCellDataComponent {
  @Input() tableElement: string | string[] = '';
  @Input() column?: TableColumn;
  @Input() dateFormat = environment.dateFormat.long;

  @Output() linkClick = new EventEmitter();

  onLinkClick() {
    this.linkClick.emit();
  }
}
