import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appLimitLines]',
})
export class LimitLinesDirective {
  @Input() maxLines = 3;
  @Input() maxLineLength = 100;

  @Output() maxLinesReached = new EventEmitter<boolean>();
  @Output() maxLineLengthReached = new EventEmitter<boolean>();

  constructor(
    public el: ElementRef,
    public renderer: Renderer2
  ) {}

  @HostListener('input', ['$event'])
  onKeyDown(event: InputEvent) {
    let text = (event.target as HTMLTextAreaElement).value;
    if (text.length > 0) {
      text = this.limitLineLength(text);
      this.renderer.setProperty(this.el.nativeElement, 'value', this.limitLineRows(text));
    }
  }

  limitLineLength(text: string): string {
    this.maxLineLengthReached.emit(false);
    const lines = text.split('\n');
    lines.forEach((line, index) => {
      if (line.length > this.maxLineLength) {
        lines[index] = line.substring(0, this.maxLineLength);
        this.maxLineLengthReached.emit(true);
      }
    });
    return lines.join('\n');
  }

  limitLineRows(text: string): string {
    this.maxLinesReached.emit(false);
    const lineCount = 1 + text.replace(/[^\n]/g, '').length;
    if (lineCount > this.maxLines) {
      const textArray = text.split('\n');
      return textArray.reduce((result, line, lineNumber) => {
        if (lineNumber < this.maxLines) {
          this.maxLinesReached.emit(true);
          return result.concat('\n', line);
        }
        return result.concat(line);
      });
    }
    return text;
  }
}
